import React, {Fragment} from 'react';
import './Modal.css';
import { Button, Modal, Container, Row, Col, Table } from 'react-bootstrap';

class Modalnya extends React.Component {

    constructor() {
        super()
        this.state = {
            show: false,
            dataKaryawan: [],
            alldraft: [],
            token: sessionStorage.getItem("access_token"),
        }
    }

    getDraft = () => {
        fetch('https://bpm.asihputera.or.id/api/1.0/asihputera/cases/draft/paged/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({
                    alldraft: responseJson.data,
                    loading: false
                })
                console.log(this.state.alldraft);
            })
    }


    handleModal = (pro, tas) => {
        this.setState({ show: !this.state.show })
        fetch(`https://bpm.asihputera.or.id/api/1.0/asihputera/project/${pro}/activity/${tas}/assignee`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({
                    dataKaryawan: responseJson,
                })
                console.log(this.state.dataKaryawan)
            })
    }

    render() {
        return (
            <div>
                {
                    this.state.alldraft.map((process) => {
                        return (
                            <Fragment>
                                <Button className="tombolAssign" onClick={this.handleModal.bind(process.pro_uid, process.tas_uid)}> Reassign </Button>
                                <Modal show={this.state.show} onHide={() => this.handleModal()}>
                                    <Modal.Header className="judulReassign" closeButton> Reassign </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row>
                                                <Col className="kotakpesan">
                                                    <textarea name="description" placeholder="Alasan Melakukan Reassign Pada Case Ini..." className="kotaknya" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Table striped bordered hover className="tabelnya">
                                                    <thead>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Mark</td>
                                                            <td>Otto</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Jacob</td>
                                                            <td>Thornton</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="tombolConfirm" onClick={() => { this.handleModal() }}>
                                            Kirim
                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Fragment>


                        )
                    })
                }
            </div>
        )
    }
}

export default Modalnya; 