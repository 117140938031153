import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Menu from './Menu/Menu';
import Login from './Login/Login';
import MenuDark from './Menu/MenuDark';
import DraftPage from './Draft/DraftPage';
import InboxPage from './Inbox/InboxPage';
import LoginDark from './Login/LoginDark';
import NewCases2 from './NewCases2/NewCases2';
import DNDraft from './DynaformDraft/DNDraft';
import DNInbox from './DynaformInbox/DNInbox';
import InboxPageDark from './Inbox/InboxPageDark';
import DraftPageDark from './Draft/DraftPageDark';
import DNNewCases from './DynaformCases/DNNewCases';
import ReportPages from './ReportPages/ReportPages';
import NewCases2Dark from './NewCases2/NewCases2Dark';
import DNDraftDark from './DynaformDraft/DNDraftDark';
import DNInboxDark from './DynaformInbox/DNInboxDark';
import ReportPagesDark from './ReportPages/ReportPagesDark';
import DNNewCasesDark from './DynaformCases/DNNewCasesDark';
import Reassign from './Assignment/Reassign';
import ReassignDark from './Assignment/ReassignDark';

class Routes extends Component {
    render() {
        return (
            <Router>
                <Fragment>
                    <Route path="/" exact component={Login} />
                    <Route path="/login" exact component={LoginDark} />

                    <Route path="/menu" component={Menu} />
                    <Route path="/menu1" component={MenuDark} />

                    <Route path="/newcases" component={NewCases2} />
                    <Route path="/newcases1" component={NewCases2Dark} />

                    <Route path="/inbox" component={InboxPage} />
                    <Route path="/inbox1" component={InboxPageDark} />

                    <Route path="/draft" component={DraftPage} />
                    <Route path="/draft1" component={DraftPageDark} />

                    <Route path="/report" component={ReportPages} />
                    <Route path="/report1" component={ReportPagesDark} />

                    <Route path="/dynaform" component={DNNewCases} />
                    <Route path="/dynaform1" component={DNNewCasesDark} />

                    <Route path="/dndraft" component={DNDraft} />
                    <Route path="/dndraft1" component={DNDraftDark} />
                    
                    <Route path="/dninbox" component={DNInbox} />
                    <Route path="/dninbox1" component={DNInboxDark} />

                    <Route path="/reassign" component={Reassign} />
                    <Route path="/reassign1" component={ReassignDark} />
                </Fragment>
            </Router>
        )
    }
}

export default Routes;