import Axios from 'axios';
import Iframe from 'react-iframe'
import React, { Component, Fragment } from 'react';
import ClickOutside from '../../components/ClickOutside';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import user2 from '../../images/user2.png';
import matahari from '../../images/matahari.png';

import '../NewCases/NewCases.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

class ReportPagesDark extends Component {


    constructor(props) {
        super(props)
        this.state = {
            username: sessionStorage.getItem("username"),
            token: sessionStorage.getItem("access_token"),
            group: sessionStorage.getItem("group"),
            departemen: sessionStorage.getItem("departemen"),
            nip: sessionStorage.getItem("nip"),
            dataSess: [],
            datauser: [],
            uid: sessionStorage.getItem("app_uid"),
            visible: false

        }
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    componentDidMount() {
        if (window.sessionStorage.getItem("access_token") === null) {
            this.props.history.push('/');
        }
        Axios.get('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/session-id',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate, br',
                    'Connection': 'Keep-Alive',
                    'Authorization': 'Bearer ' + this.state.token,
                },
                credentials: "same-origin",
            })
            .then((result) => {
                console.log(result.data);
                this.setState({
                    dataSess: result.data
                })
            })

        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/login-user", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            console.log(resultJSON)
            this.setState({
                datauser: resultJSON
            })
            this.getUserRole(this.state.datauser.uid);
        })
    }

    getUserRole = (uid) => {
        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/user/" + uid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            if (resultJSON.usr_role == "PROCESSMAKER_ADMIN") {
                this.setState({ user_role: 'Administrator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_OPERATOR") {
                this.setState({ user_role: 'Operator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_MANAGER") {
                this.setState({ user_role: 'Manager' })
            }
        })
    }

    content() {
        if (isChrome) {
            return (
                <div>
                    <Iframe src={`https://birt.asihputera.or.id:9999/birt/run?__report=1ReportIntroWin.rptdesign&Group=${this.state.group}&Departemen=${this.state.departemen}&NIP=${this.state.nip}`}
                        width="80%"
                        height="100%"
                        position="fixed" />
                </div>
            )
        }
        else {
            return (
                <div>
                    <Iframe src={`https://birt.asihputera.or.id:9999/birt/run?__report=1ReportIntroWin.rptdesign&Group=${this.state.group}&Departemen=${this.state.departemen}&NIP=${this.state.nip}`}
                        width="95.5%"
                        height="92%"
                        position="fixed" />
                </div>
            )
        }
    }



    render() {
        console.log("Props APP_UID : ", this.state.uid);
        console.log("Props Session ID : ", this.state.dataSess);
        return (
            <Fragment>
                <ClickOutside
                    onClickOutside={() => {
                        this.setState({ expanded: false });
                    }}
                >
                    <SideNav
                        expanded={this.state.expanded}
                        onToggle={(expanded) => {
                            this.setState({ expanded });
                        }}
                        onSelect={(selected) => {
                            if (selected === "") {
                                window.sessionStorage.clear();
                            }
                            const to = '/' + selected;
                            if (this.props.location.pathname !== to) {
                                this.props.history.push(to);
                            }
                        }}
                        style={{ backgroundColor: '#1f2757', position: 'fixed' }}
                    >
                        <SideNav.Toggle />
                        <SideNav.Nav>
                            <NavItem eventKey="newcases1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    New Cases
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="inbox1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Inbox
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="draft1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Draft
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="report1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Report
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Log Out
                            </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </ClickOutside>
                <div className="background-dark">
                    <div className="headerDark">
                        <Link style={{ textDecoration: 'none' }} to="/menu1">
                            <p className="headerLabel-fixDark1"> ASIH PUTERA </p>
                        </Link>
                        <p className="headerLabelUser-fixDark"> {this.state.username} </p>
                        <p className="headerLabelPass-fixDark"> {this.state.user_role} </p>
                        <img className="iNya" src={user2} />
                    </div>
                    <div className="iframenya">
                        <br />
                        <br />
                        {this.content()}
                    </div>
                    <button className="lightReport" onClick={this.toggleMenu}><img className="user2" src={matahari} /></button>
                    {this.state.visible ? this.props.history.push("/report") : null}
                </div>
            </Fragment>

        )

    }

}

export default ReportPagesDark;