import Axios from 'axios';
import Modalnya from '../../components/Modalnya';
import { confirmAlert } from 'react-confirm-alert';
import ClickOutside from '../../components/ClickOutside';
import React, { Component, Fragment, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import user2 from '../../images/user2.png';
import buang from '../../images/buang.png';
import matahari from '../../images/matahari.png';

import './DraftPage.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const Loader = () => (
    <div class="divLoader">
        <svg class="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
    </div>
);


class DraftPageDark extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: sessionStorage.getItem("username"),
            token: sessionStorage.getItem("access_token"),
            user_role: '',
            loading: true,
            alldraft: [],
            dataSess: [],
            listdraft: [],
            app_uid: '',
            visible: false
        }
        this.toggleMenu = this.toggleMenu.bind(this);
    }


    componentDidMount() {
        if (window.sessionStorage.getItem("access_token") === null) {
            this.props.history.push('/');
        }

        this.dapatDraft();

        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/login-user", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            this.getUserRole(resultJSON.uid);
        })

    }

    dapatDraft = () => {
        fetch('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases/draft/paged/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({
                    alldraft: responseJson.data,
                    loading: false
                })
                console.log(this.state.alldraft)
            })
    }


    getUserRole = (uid) => {
        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/user/" + uid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            if (resultJSON.usr_role == "PROCESSMAKER_ADMIN") {
                this.setState({ user_role: 'Administrator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_OPERATOR") {
                this.setState({ user_role: 'Operator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_MANAGER") {
                this.setState({ user_role: 'Manager' })
            }
        })
    }

    getDraft = () => {
        fetch('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases/draft/paged/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({
                    listdraft: responseJson.data,
                })
            })
    }

    tampilData = () => {
        setTimeout(() => {
            fetch('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases/draft/paged/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate',
                    'Authorization': 'Bearer ' + this.state.token,
                },
            }).then((response) => response.json())
                .then((responseJson) => {
                    console.log(responseJson);
                    this.setState({
                        listdraft: responseJson.data,
                    })
                })
        }, 10)
    }

    onDeleteDraft = (uid, title) => {
        confirmAlert({
            title: 'Perhatian!',
            message: 'Apakah Anda Ingin Mendelete Draft Dengan Nama Draft ►  ' + title + ' ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>
                        fetch(`https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases/${uid}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept-Encoding': 'gzip, deflate',
                                'Authorization': 'Bearer ' + this.state.token,
                            }
                        })
                            .then(() => {
                                this.componentDidMount();
                                alert("Data Berhasil Dihapus");
                            })
                },
                {
                    label: 'No',
                }
            ]
        })

    }

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    onDraft = (uid) => {
        window.sessionStorage.setItem("app_uidD", uid);
        this.props.history.push("/dndraft1");
    }

    onReassign = () => {
        this.props.history.push("/reassign1");
    }

    render() {
        return (
            <Fragment>
                <ClickOutside
                    onClickOutside={() => {
                        this.setState({ expanded: false });
                    }}
                >
                    <SideNav
                        expanded={this.state.expanded}
                        onToggle={(expanded) => {
                            this.setState({ expanded });
                        }}
                        onSelect={(selected) => {
                            if (selected === "") {
                                window.sessionStorage.clear();
                            }
                            const to = '/' + selected;
                            if (this.props.location.pathname !== to) {
                                this.props.history.push(to);
                            }
                        }}
                        style={{ backgroundColor: '#1f2757', position: 'fixed' }}
                    >
                        <SideNav.Toggle />
                        <SideNav.Nav>
                            <NavItem eventKey="newcases1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    New Cases
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="inbox1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Inbox
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="draft1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Draft
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="report1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Report
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Log Out
                            </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </ClickOutside>
                <div className="background-dark">
                    <div className="headerDarkDraft">
                        <Link style={{ textDecoration: 'none' }} to="/menu1">
                            <p className="headerLabel-fixDark"> ASIH PUTERA </p>
                        </Link>
                        <p className="headerLabelUser-fixDark"> {this.state.username} </p>
                        <p className="headerLabelPass-fixDark"> {this.state.user_role} </p>
                        <img className="iNya" src={user2} />
                        <button className="lightDraft" onClick={this.toggleMenu}><img className="user2" src={matahari} /></button>
                        {this.state.visible ? this.props.history.push("/draft") : null}
                    </div>
                    <p className="titlePageDark"> DRAFT LIST </p>
                    <div className="loadingCircle">
                        {this.state.loading ? <Loader /> : null}
                    </div>
                    {
                        this.state.alldraft.map((draft) => {
                            return (

                                <Fragment>
                                    <div key={draft.app_uid} className="draftListRealDark" onClick={this.onDraft.bind(this, draft.app_uid)}>
                                        <span style={{ fontSize: 20, fontWeight: 'bold' }}>{draft.app_title}</span><br />
                                        <span>{draft.app_pro_title} - {draft.app_tas_title}</span><br />
                                        <span style={{ fontSize: 15, fontWeight: 'lighter' }}>Sent on: {draft.app_update_date}</span>
                                    </div>
                                    <button className="iconBuangYRealDark" onClick={this.onDeleteDraft.bind(this, draft.app_uid, draft.app_title)}> Hapus </button>
                                    <button className="iconReassignYRealDark" onClick={this.onReassign}> Reassign </button>
                                    {/* <Modalnya /> */}
                                </Fragment>
                            )
                        })
                    }

                </div>
            </Fragment>
        )
    }
}

export default DraftPageDark;