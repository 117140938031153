import React, { Component, Fragment } from 'react';
import ClickOutside from '../../components/ClickOutside';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import user2 from '../../images/user2.png';
import Draft from '../../images/Draft.png';
import Inbox from '../../images/Inbox.png';
import bulan from '../../images/bulan.png';
import Report from '../../images/Report.png';
import NewCase from '../../images/NewCase.png';

import './Menu.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class Menu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            token: sessionStorage.getItem("access_token"),
            username: sessionStorage.getItem("username"),
            user_role: '',
            datauser: [],
            expanded: false,
            visible: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }


    onNewCasesClicked = () => {
        console.log("Ini New Cases");
    }
    onInboxClicked = () => {
        console.log("Ini Inboxnya");
    }
    onDraftClicked = () => {
        console.log("Ini Draft");
    }
    onReport = (zipcode, departemen, position) => {
        window.sessionStorage.setItem("group", zipcode);
        window.sessionStorage.setItem("departemen", departemen);
        window.sessionStorage.setItem("nip", position);
        this.props.history.push("/report");
    }

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }


    componentDidMount() {
        console.log(this.state.token);
        if (window.sessionStorage.getItem("access_token") === null) {
            this.props.history.push('/');
        }
        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/login-user", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            console.log(resultJSON)
            this.setState({
                datauser: resultJSON
            })
            console.log(this.state.datauser.zipcode)
            console.log(this.state.datauser.uid)
            this.getUserRole(this.state.datauser.uid);
        })
    }

    getUserRole = (uid) => {
        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/user/" + uid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            if (resultJSON.usr_role == "PROCESSMAKER_ADMIN") {
                this.setState({ user_role: 'Administrator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_OPERATOR") {
                this.setState({ user_role: 'Operator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_MANAGER") {
                this.setState({ user_role: 'Manager' })
            }
        })
    }

    render() {
        return (
            <Fragment>
                <ClickOutside
                    onClickOutside={() => {
                        this.setState({ expanded: false });
                    }}
                >
                    <SideNav
                        expanded={this.state.expanded}
                        onToggle={(expanded) => {
                            this.setState({ expanded });
                        }}
                        onSelect={(selected) => {
                            if (selected === "") {
                                window.sessionStorage.clear();
                            }
                            const to = '/' + selected;
                            if (this.props.location.pathname !== to) {
                                this.props.history.push(to);
                            }
                        }}
                        style={{ backgroundColor: '#015c6f', position: 'fixed'}}
                    >
                        <SideNav.Toggle />
                        <SideNav.Nav>
                            <NavItem eventKey="newcases">
                                <NavIcon>
                                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    New Cases
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="inbox">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Inbox
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="draft">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Draft
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="report">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Report
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Log Out
                            </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </ClickOutside>
                <div className="background">
                    <div className="header2">
                        <Link style={{ textDecoration: 'none' }} to="/menu">
                            <p className="headerLabel-fix"> ASIH PUTERA </p>
                        </Link>
                        <p className="headerLabelUser-fix"> {this.state.username} </p>
                        <p className="headerLabelPass-fix"> {this.state.user_role} </p>
                        <img className="iNya" src={user2} />
                        <button className="darkMenu" onClick={this.toggleMenu}><img className="user2" src={bulan} /></button>
                        {this.state.visible ? this.props.history.push("/menu1") : null}
                    </div>
                    <p className="titlePage"> MENU </p>
                    <div className="bagianMenu">
                        <Link to="/newcases">
                            <button className="buttonMenu">
                                <img className="newCaseIcon" src={NewCase} onClick={this.onNewCasesClicked} />
                            </button>
                        </Link>
                        <Link to="/inbox">
                            <button className="buttonMenu">
                                <img className="inboxIcon" src={Inbox} onClick={this.onInboxClicked} />
                            </button>
                        </Link>
                    </div>
                    <div className="bagianMenu1">
                        <Link to="/draft">
                            <button className="buttonMenu">
                                <img className="draftIcon" src={Draft} onClick={this.onDraftClicked} />
                            </button>
                        </Link>
                        <Link to="/report">
                            <button className="buttonMenu">
                                <img className="reportIcon" src={Report} onClick={this.onReport.bind(this, this.state.datauser.zipcode, this.state.datauser.departmentname, this.state.datauser.position)} />
                            </button>
                        </Link>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Menu;