import React, { Component } from 'react';

import logo from '../../images/logo.png';
import user2 from '../../images/user2.png';
import bulan from '../../images/bulan.png';

import './Login.css';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            grant_type: '',
            scope: '',
            client_id: '',
            client_secret: '',
            username: '',
            password: '',
            field: {},
            errors: {},
            isToggleOn: true,
            visible: false
        };
        
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    onLoginClicked = () => {
        let info = {
            grant_type: "password",
            scope: "*",
            client_id: 'QKQYKIBJUXFRMGITBPKXVBWOVMFYPWWF',
            client_secret: '2922119675d440616c9a613026663027',
            username: this.refs.uname.value,
            password: this.refs.pass.value
        };

        //console.log(JSON.stringify(info));

        fetch('https://bpm.asihputera.or.id:8888/asihputera/oauth2/token', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(info)
        }).then(result => result.json()).then(resultJSON => {
            if (resultJSON.error_description == "Invalid username and password combination") {
                alert(resultJSON.error_description);
            } else {
                window.sessionStorage.setItem("access_token", resultJSON.access_token);
                window.sessionStorage.setItem("username", this.refs.uname.value);
                this.props.history.push("/menu")
            }
        });
    }

    handleKeyPress = (target) => {
        if (target.key === 'Enter') {
            let info = {
                grant_type: "password",
                scope: "*",
                client_id: 'QKQYKIBJUXFRMGITBPKXVBWOVMFYPWWF',
                client_secret: '2922119675d440616c9a613026663027',
                username: this.refs.uname.value,
                password: this.refs.pass.value
            };

            //console.log(JSON.stringify(info));

            fetch('https://bpm.asihputera.or.id:8888/asihputera/oauth2/token', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(info)
            }).then(result => result.json()).then(resultJSON => {
                if (resultJSON.error_description == "Invalid username and password combination") {
                    alert(resultJSON.error_description);
                } else {
                    window.sessionStorage.setItem("access_token", resultJSON.access_token);
                    window.sessionStorage.setItem("username", this.refs.uname.value);
                    this.props.history.push("/menu")
                }
            });
        }
    }

    handleEnter() {
        console.log("Enter key is pressed");
    }

    toggleMenu() {
        this.setState({visible: !this.state.visible})
    }

    render() {
        return (
            <div className="background">
                <div className="headerLight">
                    <p className="textHeader"> ASIH PUTERA </p>
                    <img className="logo" src={logo} />
                    <button className="dark" onClick={this.toggleMenu}><img className="user2" src={bulan} /></button>
                    {this.state.visible ? this.props.history.push("/login") : null}   
                </div>
                <img className="user" src={user2} />
                <input type="text" ref="uname" name="username" placeholder="Username" className="labelUser" onKeyDown={this.handleKeyPress} />
                <div className="bagianPass" onKeyDown={this.handleKeyPress}>
                    <input type="password" ref="pass" name="password" placeholder="Password" className="labelPass" />
                    {/* <img src={mata}/> */}
                </div>
                <div className="login">
                    {/* <button className="btn" onClick={this.onLoginClicked}>Login</button> */}
                    <button className="btn" onKeyDown={this.handleKeyPress} onClick={this.onLoginClicked}>Login</button>
                </div>
            </div>
        )
    }
}

export default Login;