import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import ClickOutside from '../../components/ClickOutside';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import user2 from '../../images/user2.png';
import matahari from '../../images/matahari.png';

import './NewCases2.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class NewCases2Dark extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: sessionStorage.getItem("access_token"),
            username: sessionStorage.getItem("username"),
            user_role: '',
            search: '',
            app_uid: '',
            inMemoryCases: [],
            dataSess: [],
            allcases: [],
            dataApps: [],
            dataUser: [],
            visible: false
        }

        this.searchData = this.searchData.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        if (window.sessionStorage.getItem("access_token") === null) {
            this.props.history.push('/login');
        }
        Axios.get('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/login-user',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate',
                    'Authorization': 'Bearer ' + this.state.token,
                }
            })
            .then((result) => {
                console.log(result.data);
                this.setState({
                    dataUser: result.data
                })

                this.getUserRole(this.state.dataUser.uid);
            })

        Axios.get('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/session-id',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate',
                    'Authorization': 'Bearer ' + this.state.token,

                }
            })
            .then((result) => {
                console.log(result.data);
                this.setState({
                    dataSess: result.data
                })
            })

        Axios.get('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/case/start-cases',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate',
                    'Authorization': 'Bearer ' + this.state.token,
                }
            })
            .then((result) => {
                console.log(result.data);
                this.setState({
                    allcases: result.data,
                    inMemoryCases: result.data
                })
            })
    }

    onTask = (pro, tas) => {
        const apiUrl = 'https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases'
        const dataApp = {
            'pro_uid': pro,
            'tas_uid': tas,
        }
        Axios.post(apiUrl, dataApp,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip, deflate',
                    'Authorization': 'Bearer ' + this.state.token,
                },

            }).then((result) => {
                console.log(result.data);
                this.setState({
                    dataApps: result.data
                })
                window.sessionStorage.setItem("app_uid", result.data.app_uid);
                this.props.history.push("/dynaform1");
            })

    }

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    getUserRole = (uid) => {
        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/user/" + uid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            if (resultJSON.usr_role == "PROCESSMAKER_ADMIN") {
                this.setState({ user_role: 'Administrator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_OPERATOR") {
                this.setState({ user_role: 'Operator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_MANAGER") {
                this.setState({ user_role: 'Manager' })
            }
        })
    }

    searchData = (event) => {
        this.setState({ search: event.target.value })
        const searchData = this.state.inMemoryCases.filter(
            data => {
                let lowerCase = (data.pro_title).toLowerCase()
                let searchLowerCase = this.state.search.toLowerCase()

                return lowerCase.indexOf(searchLowerCase) > -1;
            }
        );
        this.setState({ allcases: searchData });
    }

    render() {
        console.log(this.state.dataApps.app_uid)
        return (
            <Fragment>
                <ClickOutside
                    onClickOutside={() => {
                        this.setState({ expanded: false });
                    }}
                >
                    <SideNav
                        expanded={this.state.expanded}
                        onToggle={(expanded) => {
                            this.setState({ expanded });
                        }}
                        onSelect={(selected) => {
                            if (selected === "") {
                                window.sessionStorage.clear();
                            }
                            const to = '/' + selected;
                            if (this.props.location.pathname !== to) {
                                this.props.history.push(to);
                            }
                        }}
                        style={{ backgroundColor: '#1f2757', position: 'fixed' }}
                    >
                        <SideNav.Toggle />
                        <SideNav.Nav>
                            <NavItem eventKey="newcases1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    New Cases
                    </NavText>
                            </NavItem>
                            <NavItem eventKey="inbox1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Inbox
                    </NavText>
                            </NavItem>
                            <NavItem eventKey="draft1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Draft
                    </NavText>
                            </NavItem>
                            <NavItem eventKey="report1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Report
                    </NavText>
                            </NavItem>
                            <NavItem eventKey="">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Log Out
                    </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </ClickOutside>
                <div className="background-dark">
                    <div className="headerDark">
                        <Link style={{ textDecoration: 'none' }} to="/menu1">
                            <p className="headerLabel-fixDark"> ASIH PUTERA </p>
                        </Link>
                        <p className="headerLabelUser-fixDark"> {this.state.username} </p>
                        <p className="headerLabelPass-fixDark"> {this.state.user_role} </p>
                        <img className="iNya" src={user2} />
                        <button className="lightNewCases" onClick={this.toggleMenu}><img className="user2" src={matahari} /></button>
                        {this.state.visible ? this.props.history.push("/newcases") : null}
                    </div>
                    <p className="titlePageDark"> PROCESS LIST </p>
                    <div className="form-search">
                        <input type="text" value={this.state.search} onChange={this.searchData} placeholder="search"></input>
                    </div>
                    <Fragment>
                        <br />
                        {
                            this.state.allcases.map((process) => {
                                return (
                                    <Fragment>
                                        <div className="caseListRealDark" onClick={this.onTask.bind(this, process.pro_uid, process.tas_uid)}>
                                            {process.pro_title}
                                        </div>
                                    </Fragment>
                                )
                            })
                        }
                    </Fragment>
                </div>
            </Fragment>
        )
    }

}


export default NewCases2Dark;