import React, { Component, Fragment } from 'react';
import ClickOutside from '../../components/ClickOutside';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import user2 from '../../images/user2.png';
import matahari from '../../images/matahari.png';

import './InboxPage.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';


class InboxPageDark extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: sessionStorage.getItem("username"),
            token: sessionStorage.getItem("access_token"),
            user_role: '',
            allinbox: [],
            dataSess: [],
            listinbox: [],
            app_uid: '',
            visible: false
        }

        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    componentDidMount() {
        if (window.sessionStorage.getItem("access_token") === null) {
            this.props.history.push('/');
        }

        fetch('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({
                    allinbox: responseJson,
                })
                console.log(this.state.allinbox)
            })

        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/extrarest/login-user", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            this.getUserRole(resultJSON.uid);
        })

    }

    getUserRole = (uid) => {
        fetch("https://bpm.asihputera.or.id:8888/api/1.0/asihputera/user/" + uid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then(result => result.json()).then(resultJSON => {
            if (resultJSON.usr_role == "PROCESSMAKER_ADMIN") {
                this.setState({ user_role: 'Administrator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_OPERATOR") {
                this.setState({ user_role: 'Operator' })
            } else if (resultJSON.usr_role == "PROCESSMAKER_MANAGER") {
                this.setState({ user_role: 'Manager' })
            }
        })
    }

    getInbox = () => {
        fetch('https://bpm.asihputera.or.id:8888/api/1.0/asihputera/cases', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Authorization': 'Bearer ' + this.state.token,
            },
        }).then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson);
                this.setState({
                    listinbox: responseJson,
                })
            })
    }

    onInbox = (uid, index) => {
        window.sessionStorage.setItem("app_uidInbox", uid);
        window.sessionStorage.setItem("index", index)
        this.props.history.push("/dninbox1");
    }

    render() {
        return (
            <Fragment>
                <ClickOutside
                    onClickOutside={() => {
                        this.setState({ expanded: false });
                    }}
                >
                    <SideNav
                        expanded={this.state.expanded}
                        onToggle={(expanded) => {
                            this.setState({ expanded });
                        }}
                        onSelect={(selected) => {
                            if (selected === "") {
                                window.sessionStorage.clear();
                            }
                            const to = '/' + selected;
                            if (this.props.location.pathname !== to) {
                                this.props.history.push(to);
                            }
                        }}
                        style={{ backgroundColor: '#1f2757', position: 'fixed' }}
                    >
                        <SideNav.Toggle />
                        <SideNav.Nav>
                            <NavItem eventKey="newcases1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    New Cases
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="inbox1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Inbox
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="draft1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Draft
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="report1">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Report
                            </NavText>
                            </NavItem>
                            <NavItem eventKey="">
                                <NavIcon>
                                    <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                                </NavIcon>
                                <NavText>
                                    Log Out
                            </NavText>
                            </NavItem>
                        </SideNav.Nav>
                    </SideNav>
                </ClickOutside>
                <div className="background-dark">
                    <div className="headerDark">
                        <Link style={{ textDecoration: 'none' }} to="/menu1">
                            <p className="headerLabel-fixDark"> ASIH PUTERA </p>
                        </Link>
                        <p className="headerLabelUser-fixDark"> {this.state.username} </p>
                        <p className="headerLabelPass-fixDark"> {this.state.user_role} </p>
                        <img className="iNya" src={user2} />
                        <button className="lightInbox" onClick={this.toggleMenu}><img className="user2" src={matahari} /></button>
                        {this.state.visible ? this.props.history.push("/inbox") : null}
                    </div>
                    <p className="titlePageDark"> INBOX LIST </p>
                    {
                        this.state.allinbox.map((inbox) => {
                            return (
                                <Fragment>
                                    <div key={inbox.app_uid} className="draftListRealDark" onClick={this.onInbox.bind(this, inbox.app_uid, inbox.del_index)}>
                                        <span style={{ fontSize: 20, fontWeight: 'bold' }}>{inbox.app_title}</span><br />
                                        <span>{inbox.app_pro_title} - {inbox.app_tas_title}</span><br />
                                        <span style={{ fontSize: 15, fontWeight: 'lighter' }}>Sent on: {inbox.app_update_date}</span>
                                    </div>
                                </Fragment>
                            )
                        })
                    }
                </div>
            </Fragment>
        )
    }
}

export default InboxPageDark;   